import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledStreaming, StyledTitle, StyledWrapper } from './styles';
import { LiveFeedContainer } from '../live-feed-container';
import { Typography } from '../../../../ui/atoms/typography';
import { generateEmbedUrl } from '../../../media/lib';
import { MediaItem } from '../../../media/components/media-item';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

export const LiveStreamTwitterContainer = ({ id }) => {
  const { t } = useTranslation(`livestream-twitter/${id}`);
  const mediaItem = t('content.mediaItem', { returnObjects: true })?.[0] || {};
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  useEffect(() => {
    dispatchCompleteEvent({
      id,
      name: FEATURE_NAMES.liveStreamTwitter,
    });
  }, [dispatchCompleteEvent, id]);

  return (
    <StyledWrapper>
      <StyledStreaming>
        <StyledTitle alignItems="center">
          <img src="/icons/play-youtube.svg" width={30} />
          <Typography bold size="heading2">
            YouTube
          </Typography>
        </StyledTitle>
        <div>
          <MediaItem
            imageUrl={mediaItem.image?.filename}
            videoUrl={generateEmbedUrl(
              mediaItem?.videoPlatform,
              mediaItem?.videoEmbedId,
              mediaItem?.videoEmbedUrlParams,
            )}
          />
        </div>
      </StyledStreaming>
      <LiveFeedContainer id={id} />
    </StyledWrapper>
  );
};
