import styled from 'styled-components';
import { media } from '../../mixins/media';

export const StyledRoot = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  overflow-y: auto;
`;

export const StyledWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${media.sm`
    width: 700px;
  `}  
  
  ${media.md`
    width: 800px;
  `}
`;

export const StyledTopHexagon = styled.div`
  position: relative;
  height: 56px;
  flex: 0 0 56px;
  
  ${media.sm`
    height: 87px;
    flex: 0 0 87px;
  `}
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: calc(50% - 130px);
    height: 100%;
    background: ${({ theme }) => theme.colors.$dark900};
    border-left: 3px solid ${({ theme }) => theme.colors.$dark700};
    border-top: 3px solid ${({ theme }) => theme.colors.$dark700};
    border-radius: 20px 0 0 0;
  }  
  
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: calc(50% - 130px);
    height: 100%;
    background: ${({ theme }) => theme.colors.$dark900};
    border-right: 3px solid ${({ theme }) => theme.colors.$dark700};
    border-top: 3px solid ${({ theme }) => theme.colors.$dark700};
    border-radius: 0 20px 0 0;
  }
`;

export const StyledInnerWrapper = styled.div`
  z-index: 3;
  position: relative;
  background: ${({ theme }) => theme.colors.$dark900};
  border-left: 3px solid ${({ theme }) => theme.colors.$dark700};
  border-right: 3px solid ${({ theme }) => theme.colors.$dark700};
  border-bottom: 3px solid ${({ theme }) => theme.colors.$dark700};
  border-top: ${({ topBorder }) => topBorder ? '3px solid #505363' : 'none'};
  box-shadow: 0 20px 15px rgba(23, 24, 29, 0.3), 0 10px 0 ${({ theme }) => theme.colors.$dark800};
  border-radius: ${({ topBorder }) => topBorder ? '20px' : '0 0 20px 20px'};
`;

export const StyledIcon = styled.div`
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 60px;
  display: block;
  width: 96px;
  height: 89px;
  user-select: none;
  z-index: 9;
  
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledUserIcon = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    height: 100%;
  }
`;
