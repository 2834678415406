import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';
import { Normalize } from 'styled-normalize';
import {
  useFetchProjectInfo,
  useFetchProjectNavigation,
  useProject,
  useTheme,
} from './features/system/hooks/use-system-store';
import { GlobalStyle } from './ui/theme/global-styles';
import { Header } from './features/app/components/header';
import { Footer } from './features/app/components/footer';
import { ModalManagerProvider } from './features/modal/components/modal-manager-provider';
import { HomePage } from './pages/home';
import { TermsPage } from './pages/terms';
import { LiveStreamPage } from './pages/live-stream';
import { BroadcastArenaPage } from './pages/broadcast/arena';
import { RouteE } from './config/navigation.config';
import { WebviewPage } from './pages/webview';
import { CompetitionPage } from './pages/competition';
import { EventManagerContainer } from './features/3dvista/containers/event-manager-container';
import { useMetadata } from './hooks/use-metadata';
import { NotificationsContainer } from './features/intro-tour/containers/notifications';
import { useDispatch3dvistaNavigationEvent } from './hooks/use-dispatch-3dvista-navigation-event';
import { JitsuContainer } from './features/app/containers/jitsu-container';
import { JitsuPageView } from './features/app/components/jitsu-page-view';

function App() {
  const theme = useTheme();
  const fetchProjectInfo = useFetchProjectInfo();
  const fetchProjectNavigation = useFetchProjectNavigation();
  const dispatch3dvistaNavigationEvent = useDispatch3dvistaNavigationEvent();
  const project = useProject();

  useMetadata({
    title: project.projectName,
  });

  useEffect(() => {
    fetchProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project.configuration?.navigationId) {
      fetchProjectNavigation(project.configuration?.navigationId);
    }
  }, [fetchProjectNavigation, project.configuration?.navigationId]);

  useEffect(() => {
    if (project.configuration?.gaMeasurementId) {
      ReactGA.initialize(project.configuration?.gaMeasurementId);
      ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: document.title,
      });
    }
  }, [project.configuration?.gaMeasurementId]);

  useEffect(() => {
    if (project.configuration?.entryNavEventId) {
      setTimeout(() => {
        dispatch3dvistaNavigationEvent(project.configuration?.entryNavEventId);
      }, 0);
    }
  }, [dispatch3dvistaNavigationEvent, project.configuration?.entryNavEventId]);

  return (
    <Suspense fallback="Loading...">
      <JitsuContainer configuration={project?.configuration}>
        {({ jitsuClient }) => (
          <BrowserRouter>
            {!!jitsuClient && !!project.configuration?.jaTrackPageviews && <JitsuPageView />}
            <Reset />
            <Normalize />
            <React.Suspense fallback="loading...">
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <ModalManagerProvider />
                <div>
                  <Header showLogo={!!project.configuration?.displayHeaderLogo} />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path={RouteE.liveStream} element={<LiveStreamPage />} />
                    <Route path={RouteE.broadcastArena} element={<BroadcastArenaPage />} />
                    <Route path={RouteE.webview} element={<WebviewPage />} />
                    <Route path={RouteE.competition} element={<CompetitionPage />} />
                    <Route path={RouteE.terms} element={<TermsPage />} />
                  </Routes>
                  <NotificationsContainer />
                  <Footer />
                  <EventManagerContainer />
                </div>
              </ThemeProvider>
            </React.Suspense>
          </BrowserRouter>
        )}
      </JitsuContainer>
    </Suspense>
  );
}

export default App;
