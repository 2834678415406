import React, { useCallback, useState } from 'react';
import { useIntroTourStoreApi } from '../../hooks/use-intro-tour-store-api';
import { Notification } from '../../components/notification';
import { StyledWrapper } from './styles';
import { useDispatch3dvistaNavigationEvent } from '../../../../hooks/use-dispatch-3dvista-navigation-event';

export const NotificationsContainer = () => {
  const { notifications } = useIntroTourStoreApi();

  const dispatchEvent = useDispatch3dvistaNavigationEvent();

  const [listRemoveNotification, setListRemoveNotification] = useState([]);
  const closedNotification = (id) => setListRemoveNotification(
    (prev) => prev.indexOf(id) === -1 ? [...prev, id] : prev,
  );
  const handleClick = useCallback((notification) => {
    closedNotification(notification.eventId);
    if (notification.notificationEventId) {
      dispatchEvent(notification.notificationEventId);
    }
  }, [dispatchEvent]);

  const filteredShowNotification = (n, lrn) => n.filter(({ eventId }) => !lrn.includes(eventId));

  if (!notifications.length) {
    return null;
  }

  return (
    <StyledWrapper>
      {filteredShowNotification(notifications, listRemoveNotification).map((notification) => (
        <Notification
          key={notification.eventId}
          {...notification}
          closedNotification={closedNotification}
          onClick={() => handleClick(notification)}
        />
      ))}
    </StyledWrapper>
  );
};
