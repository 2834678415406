export const EVENT_COMPLETED = '3dvista-event-completed';

export const FEATURE_NAMES = {
  accessCode: 'xpAccessCode',
  floorPlan: 'xpFloorPlan',
  staticPage: 'xpStaticPage',
  mediaGallery: 'xpMediaGallery',
  newsletter: 'xpNewsletter',
  game: 'xpGamification',
  notification: 'xpLocation',
  shareToAccess: 'xpShareToAccess',
  liveStreamTwitter: 'xpLivestreamTwitter',
  liveStreamArena: 'xpLivestreamArena',
  competition: 'xpCompetition',
  welcome: 'xpWelcome',
  iframe: 'xpIframe',
  videoGalleryFlick: 'xpVideoGalleryFlick',
  shareToSocial: 'xpShareToSocial',
};
