import styled from 'styled-components';
import { media } from '../../mixins/media';

export const StyledWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-top: ${({ isPortrait, offset }) => isPortrait ? '0' : offset};
    padding-bottom: ${({ isPortrait }) => isPortrait ? '177.78%' : '0'};
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  ${media.sm`
    border-radius: 20px 20px 0 0;
  `}
`;
