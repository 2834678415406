import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  StyledAgreement, LeftBar, RightBar, StyledContent, StyledTitle, StyledError, StyledSuccess,
} from './styles';
import { TextInputWithButton } from '../../../../ui/molecules/text-input-with-button';
import { Checkbox } from '../../../../ui/atoms/checkbox';
import { isEmailValid } from '../../../../config/validations';
import { useSubscribe } from '../../hooks/use-subscribe';
import { MediaItem } from '../../../media/components/media-item';
import { generateEmbedUrl } from '../../../media/lib';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

export const NewsletterForm = ({ id }) => {
  const { sending, sent, subscribe } = useSubscribe(id);
  const [email, setEmail] = useState('');
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [errors, setErrors] = useState({
    agree: false,
    email: false,
  });
  const { t } = useTranslation(`newsletters/${id}`);
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  const submit = useCallback(async () => {
    const emailValid = isEmailValid(email);
    setErrors({
      agree: false,
      email: false,
    });
    if (!emailValid || !agreeChecked) {
      setErrors({
        agree: !agreeChecked,
        email: !emailValid,
      });
      return;
    }

    await subscribe({ email });
  }, [agreeChecked, email, subscribe]);

  useEffect(() => {
    if (sent) {
      setEmail('');
      dispatchCompleteEvent({
        id,
        name: FEATURE_NAMES.newsletter,
      });
    }
  }, [dispatchCompleteEvent, id, sent]);

  const mediaItem = t('content.mediaItem.0', { returnObjects: true });

  if (sent) {
    return (
      <StyledSuccess>
        {t('content.formSubmitSuccessText')}
      </StyledSuccess>
    );
  }

  return (
    <StyledContent>
      <LeftBar>
        <StyledTitle tag="div" variant="title" size="heading1" bold>
          <Trans as="span" i18nKey="newsletterLatestUpdates.title">
            {t('content.headingPrimary')}
          </Trans>
        </StyledTitle>
        <TextInputWithButton
          stretch
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onButtonClick={submit}
          buttonDisabled={sending}
          buttonTitle={t('content.formButtonSubmitText')}
          buttonIcon="paper-plain"
          placeholder={t('content.formInputEmailAddressPlaceholder')}
        />
        {errors.email && (
          <StyledError alignItems="center">
            <img src="/icons/error.svg" />
            {t('content.formSubmitEmailError')}
          </StyledError>
        )}
        <StyledAgreement>
          <Checkbox value={agreeChecked} onChange={(e) => setAgreeChecked(e.target.checked)} />
          <span dangerouslySetInnerHTML={{ __html: t('content.formContentDisclaimerText') }} />
        </StyledAgreement>
        {errors.agree && (
          <StyledError alignItems="center">
            <img src="/icons/error.svg" />
            {t('content.formSubmitTermsError')}
          </StyledError>
        )}
      </LeftBar>
      <RightBar>
        {(mediaItem?.videoPlatform || mediaItem?.image) && (
          <MediaItem
            isPortrait
            imageUrl={mediaItem.image?.filename}
            videoUrl={generateEmbedUrl(
              mediaItem?.videoPlatform,
              mediaItem?.videoEmbedId,
              mediaItem?.videoEmbedUrlParams,
            )}
          />
        )}
      </RightBar>
    </StyledContent>
  );
};
