import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntroTourStoreApi } from './use-intro-tour-store-api';
import { LocationAPI } from '../../../api/location';
import { useGoToPanorama } from '../../3dvista/hooks/use-3dvista-actions';

export const useFetchNotifications = () => {
  const { setNotifications } = useIntroTourStoreApi();
  const { i18n } = useTranslation();
  const goToPanorama = useGoToPanorama();

  return useCallback(async ({ id }) => {
    const res = await LocationAPI.fetchById(id);
    const notification = res.data.content[i18n?.language];
    if (notification) {
      setNotifications([notification]);
    }
    if (notification.systemName) {
      goToPanorama(notification.systemName);
    }
  }, [goToPanorama, i18n?.language, setNotifications]);
};
