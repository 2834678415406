import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloorPlanModal } from '../../floor-plan/modals/floor-plan-modal';
import { NewsletterModal } from '../../newsletter/modals/newsletter-modal';
import { SingleVideoModal } from '../../media/modals/single-video-modal';
import { RouteE } from '../../../config/navigation.config';
import { LiveStreamArenaModal } from '../../livestream-arena/modals/live-stream-arena-modal';
import { LiveStreamTwitterModal } from '../../live-stream/modals/live-stream-twitter-modal';
import { GamesListModal } from '../../game/modals/games-list-modal';
import { WebViewModal } from '../../webview/modals/webview-modal';
import { MediaGalleryModal } from '../../media/modals/media-gallery-modal';
import { StaticPageModal } from '../../static-page/modal/static-page-modal';
import { VideoFlickGalleryModal } from '../../flick-gallery/modals/video-flick-gallery-modal';
import { ShareModal } from '../modals/share-modal';
import { AccessCodeModal } from '../../auth/modals/access-code-modal';
import { ShareAccessModal } from '../modals/share-access-modal';
import { useFetchNotifications } from '../../intro-tour/hooks/use-fetch-notifications';
import { FEATURE_NAMES } from '../../../config/features.config';
import { WelcomeModal } from '../../welcome/modals/welcome-modal';

export const useEventBindings = () => {
  const navigate = useNavigate();
  const fetchNotifications = useFetchNotifications();

  return useMemo(
    () => ({
      [FEATURE_NAMES.floorPlan]: (payload = {}) => FloorPlanModal.open(payload),
      [FEATURE_NAMES.newsletter]: (payload = {}) => NewsletterModal.open(payload),
      [FEATURE_NAMES.media]: (payload = {}) => SingleVideoModal.open(payload),
      [FEATURE_NAMES.competition]: (payload = {}) => navigate(`${RouteE.competition}?typeformId=${payload.id}`),
      [FEATURE_NAMES.welcome]: (payload = {}) => WelcomeModal.open(payload),
      [FEATURE_NAMES.liveStreamArena]: (payload = {}) => LiveStreamArenaModal.open(payload),
      [FEATURE_NAMES.liveStreamTwitter]: (payload = {}) => LiveStreamTwitterModal.open(payload),
      [FEATURE_NAMES.game]: (payload = {}) => GamesListModal.open(payload),
      [FEATURE_NAMES.iframe]: (payload = {}) => WebViewModal.open(payload),
      [FEATURE_NAMES.mediaGallery]: (payload = {}) => MediaGalleryModal.open(payload),
      [FEATURE_NAMES.staticPage]: (payload = {}) => StaticPageModal.open(payload),
      [FEATURE_NAMES.videoGalleryFlick]: (payload = {}) => VideoFlickGalleryModal.open(payload),
      [FEATURE_NAMES.shareToSocial]: (payload = {}) => ShareModal.open(payload),
      [FEATURE_NAMES.accessCode]: (payload = {}) => AccessCodeModal.open(payload),
      [FEATURE_NAMES.shareToAccess]: (payload = {}) => ShareAccessModal.open(payload),
      [FEATURE_NAMES.notification]: (payload = {}) => fetchNotifications(payload),
    }),
    [fetchNotifications, navigate],
  );
};
