import React, { useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { modalRegistry } from '../../providers/modal.registry';
import { StyledWrapper } from './modal-component.styles';

const ModalComponent = ({ modal }) => {
  const {
    id, preventEasyClose, component: Component, opened, props: modalProps,
  } = modal;

  const closeModal = useCallback(() => {
    modalRegistry.get(id)?.close();
  }, [id]);

  const onOverlayClick = useCallback((e) => {
    const isLang = e?.target?.closest('[data-component="lang-switcher"]');
    // eslint-disable-next-line no-unused-expressions
    !preventEasyClose && !isLang && closeModal();
  }, [preventEasyClose, closeModal]);

  if (!Component || !opened) {
    return null;
  }

  return (
    <StyledWrapper>
      <OutsideClickHandler onOutsideClick={onOverlayClick} display="contents">
        <Component {...modalProps} closeModal={closeModal} />
      </OutsideClickHandler>
    </StyledWrapper>
  );
};

export default ModalComponent;
