import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledWrapperCloseIcon } from './styles';
import { modalRegistry } from '../../../features/modal/providers/modal.registry';

export const ButtonClose = ({ onClick, languageMod, ...rest }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/${window.location.hash}`);
    modalRegistry.modals.forEach((modal) => {
      // eslint-disable-next-line no-unused-expressions
      modal.opened && modal.close();
    });
    onClick?.();
  }, [navigate, onClick]);

  return (
    <StyledWrapperCloseIcon {...rest} onClick={handleClick} />
  );
};
