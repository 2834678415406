import React from 'react';
import { useTheme } from 'styled-components';
import { useScreenDetect } from '../../../hocs/use-screen-detect';
import { StyledIcon } from './styles';

export const HexagonLayoutIcon = ({ small }) => {
  const screen = useScreenDetect();
  const theme = useTheme();

  const showSmall = typeof small === 'boolean' ? small : screen.xs;

  const fill = theme.colors.$dark900;
  const stroke = theme.colors.$dark700;

  return showSmall ? (
    <StyledIcon width="260" height="56" viewBox="0 0 260 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_742_17642)">
        <path d="M278.5 20V280C278.5 290.217 270.217 298.5 260 298.5H-1.90735e-06C-10.2173 298.5 -18.5 290.217 -18.5 280V20C-18.5 9.78273 -10.2173 1.5 0 1.5H60.1452C66.9064 1.5 73.1291 5.18844 76.3745 11.1198L93.9937 43.3203C97.7654 50.2134 104.997 54.5 112.855 54.5H130H147.145C155.003 54.5 162.235 50.2134 166.006 43.3202L183.625 11.1198C186.871 5.18843 193.094 1.5 199.855 1.5H260C270.217 1.5 278.5 9.78273 278.5 20Z" fill={fill} stroke={stroke} strokeWidth="3" />
      </g>
      <defs>
        <clipPath id="clip0_742_17642">
          <rect width="260" height="56" fill="white" />
        </clipPath>
      </defs>
    </StyledIcon>
  ) : (
    <StyledIcon width="560" height="87" viewBox="0 0 560 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_742_17641)">
        <path d="M578.5 20V380C578.5 390.217 570.217 398.5 560 398.5H1.71661e-05C-10.2173 398.5 -18.5 390.217 -18.5 380V20C-18.5 9.78274 -10.2173 1.5 0 1.5H176.218C182.944 1.5 189.139 5.14979 192.399 11.0322L226.977 73.4221C230.766 80.2584 237.966 84.5 245.782 84.5H280H314.218C322.034 84.5 329.234 80.2584 333.023 73.4221L367.601 11.0322C370.861 5.14978 377.056 1.5 383.782 1.5H560C570.217 1.5 578.5 9.78273 578.5 20Z" fill={fill} stroke={stroke} strokeWidth="3" />
      </g>
      <defs>
        <clipPath id="clip0_742_17641">
          <rect width="560" height="87" fill="white" />
        </clipPath>
      </defs>
    </StyledIcon>
  );
};
