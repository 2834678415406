import React from 'react';
import { VideoFrame } from '../../../../ui/molecules/video-frame';
import { StyledImage } from './styles';

export const MediaItem = ({ videoUrl, imageUrl, isPortrait }) => (
  videoUrl ? (
    <VideoFrame
      url={videoUrl}
      isPortrait={!!isPortrait}
    />
  ) : <StyledImage src={imageUrl} />
);
