import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './styles';

export const VideoFrame = ({ url, isPortrait }) => {
  const offset = useMemo(() => {
    if (url.includes('vimeo')) {
      return '72.25%';
    }
    return '56.25%';
  }, [url]);

  return (
    <StyledWrapper offset={offset} isPortrait={isPortrait}>
      <iframe
        src={url}
        title="YouTube video player"
        frameBorder="0"
        controls="1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </StyledWrapper>
  );
};

VideoFrame.propTypes = {
  url: PropTypes.string.isRequired,
  isPortrait: PropTypes.bool,
};
