export const generateEmbedUrl = (platform, id, params) => {
  switch (platform) {
    case 'youtube':
      return `https://www.youtube.com/embed/${id}?${params}`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${id}?${params}`;
    default:
      return id;
  }
};
