import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledFrame, StyledIcons, StyledStreaming, StyledWrapper,
} from './styles';
import { StyledTitle } from '../../../live-stream/containers/live-stream-container/styles';
import { generateEmbedUrl } from '../../../media/lib';
import { MediaItem } from '../../../media/components/media-item';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

export const LiveStreamArenaContainer = ({ id }) => {
  const { t } = useTranslation(`livestream-arena/${id}`);
  const mediaItem = t('content.mediaItem', { returnObjects: true })?.[0] || {};
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  useEffect(() => {
    dispatchCompleteEvent({
      id,
      name: FEATURE_NAMES.liveStreamArena,
    });
  }, [dispatchCompleteEvent, id]);

  return (
    <StyledWrapper>
      <StyledStreaming>
        <StyledTitle alignItems="center">
          <img src="/icons/play-youtube.svg" width={30} />
          <span>YouTube</span>
        </StyledTitle>
        <div>
          <MediaItem
            imageUrl={mediaItem.image?.filename}
            videoUrl={generateEmbedUrl(
              mediaItem?.videoPlatform,
              mediaItem?.videoEmbedId,
              mediaItem?.videoEmbedUrlParams,
            )}
          />
        </div>
      </StyledStreaming>
      <StyledFrame>
        <StyledTitle alignItems="center">
          <StyledIcons>
            <img src="/icons/live-feed.svg" width={30} />
          </StyledIcons>
          <span>Live Feed</span>
        </StyledTitle>
        <iframe title="Arena" src={t('content.arenaChatSrc')} />
      </StyledFrame>
    </StyledWrapper>
  );
};
