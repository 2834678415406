import React, { useEffect, useState } from 'react';
import { createClient, JitsuProvider } from '@jitsu/react';
import { useTranslation } from 'react-i18next';
import { PROJECT_ID } from '../../../../config/common.config';

export const JitsuContainer = ({ configuration, children }) => {
  const [jitsuClient, setJitsuClient] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const { jaHostname, jaKey } = configuration || {};
    if (!jitsuClient && jaHostname && jaKey) {
      const client = createClient({
        tracking_host: jaHostname,
        key: jaKey,
      });
      setJitsuClient(client);

      client?.track('Navigation > Enter xSpace', {
        projectId: PROJECT_ID,
        prefLocale: i18n?.language,
      });
    }
  }, [i18n?.language, configuration, jitsuClient]);

  if (!jitsuClient) {
    return <>{children({ jitsuClient })}</>;
  }

  return (
    <JitsuProvider client={jitsuClient}>
      {children({ jitsuClient })}
    </JitsuProvider>
  );
};
